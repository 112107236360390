import MDXRenderer from "gatsby-mdx/mdx-renderer"
import React from "react"
import { Link, graphql } from "gatsby"
import "./base-styles.scss"
import "./LayoutCommon"
import { baseClass, childClass } from "./LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { Mdx, FileConnection, MdxConnection } from "@GraphQLModels";
import MdxBlock from "components/texty/MdxBlock";
import { PedalDataContext, PedalDataContextProps } from "@Contexts/PedalDataContext";
import { Pedal, BuyLinkCollection, ArticleMetaData } from "@ViewModels";
import FixedShareLinks from "components/buttons/FixedShareLinks";
import { Helmet } from "react-helmet";
import SiteFooter from "components/footer/SiteFooter";
import RelatedArticlesList from "components/explorers/RelatedArticlesList"
import ArticleLinkCard from "components/cards/ArticleLinkCard";
import FixedHeightImage from "components/media/FixedHeightImage"
import ArticleSubTitleBar from "components/texty/ArticleSubTitleBar"
import PageMeta from "components/texty/PageMeta"
import { getArticleStructuredData } from "components/structured-data/ArticleStructuredData"


interface IProps {
  pageContext: {
    id: string,
    buyPedalLinks: string[],
    series: string
    readNext: string
  };
  data: {
    mdx: Mdx,
    buyLinkPedals: MdxConnection
    articlesInSeries: MdxConnection
    readNext: Mdx
  }
}

export default function ArticleLayout(props: IProps) {
  const buyLinkPedals = props.data.buyLinkPedals ? props.data.buyLinkPedals.edges.map(x => Pedal.fromMdxNode(x.node)) : [];
  const buyLinkArray = (props.data.mdx.frontmatter.buyLinks || []).filter(x => !!x && !!x.idList).map(link => BuyLinkCollection.fromMdxMeta(link, buyLinkPedals));
  const pedalData: PedalDataContextProps = {
    buyLinks: Object.assign({}, ...buyLinkArray.map((item, index) => ({
      [`article-${index + 1}`]: item
    })))
  };
  const articlesInSeries = props.data.articlesInSeries ? props.data.articlesInSeries.edges
    .filter(x => x.node.fields.route != props.data.mdx.fields.route)
    .map(edge => ArticleMetaData.from(edge.node))
    : [];
  const article = ArticleMetaData.from(props.data.mdx);

  return (
    <div className={baseClass}>
      <PageMeta
        title={props.data.mdx.frontmatter.title}
        route={props.data.mdx.fields.route}
        description={props.data.mdx.frontmatter.subTitle}
        imageUrl={props.data.mdx.frontmatter.mainImage} >
        <meta property="og:type" content="article" />
        <script className='structured-data-list' type="application/ld+json">{getArticleStructuredData(article)}</script>
      </PageMeta>
      <SiteHeader />
      <FixedHeightImage className={childClass('full-width')}
        src={props.data.mdx.frontmatter.mainImage}
        height='350px' />
      <PedalDataContext.Provider value={pedalData}>
        <section className={childClass('relative')}>
          <FixedShareLinks title={article.title} route={article.route} />
          <MdxBlock mdx={props.data.mdx}
            className={[childClass('container', 'reading-mode'), childClass('relative')].join(' ')}
            showTitle={true}
            subTitle={<ArticleSubTitleBar timeToRead={props.data.mdx.timeToRead} date={new Date(props.data.mdx.frontmatter.date)} />} />
        </section>
      </PedalDataContext.Provider>
      {articlesInSeries.length > 0 && <RelatedArticlesList heading={`Other articles in this series`} articles={articlesInSeries} className={childClass('container')} />}
      {props.data.readNext && <div className={[childClass('container'), childClass('row')].join(' ')}>
        <h3>Read Next</h3>
        <ArticleLinkCard articleMeta={ArticleMetaData.from(props.data.readNext)} className={childClass('row')} inPanel={false} />
      </div>}
      <SiteFooter />
    </div>
  )
}

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query ArticleLayoutQuery($id: String, $series: String, $buyPedalLinks: [String], $readNext: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        timeToRead
        frontmatter {
          title
          subTitle
          mainImage
          standfirst
          date
          buyLinks {
            title
            idList
          }
        }
        code {
          body
        }
      }
      buyLinkPedals: allMdx( filter: { fields: { route : { in: $buyPedalLinks } } } ) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              title
            }
          }
        }
      }
      articlesInSeries: allMdx( filter: { frontmatter: { series: { eq : $series } } } ) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              title
              mainImage
              date
              standfirst
            }
          }
        }
      }
      readNext: mdx(fields: { route: { eq: $readNext } }) {
        id
        fields {
          route
        }
        frontmatter {
          title
          mainImage
          standfirst
          date
        }
      }
    }
  `

