import * as React from 'react';
import './FixedShareLinks.scss';
import { baseClassAnd, childClassClosure, createFacebookShareLink, createTwitterShareLink, createEmailShareLink } from '@Functions';
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const baseClass = 'fixed-share-links';
const childClass = childClassClosure(baseClass);

interface FixedShareLinksProps {
  className?: string;
  route: string;
  title: string;
}

const FixedShareLinks: React.SFC<FixedShareLinksProps> = (props) => {
  return <div className={baseClassAnd(baseClass, props)} >
    <OutboundLink href={createFacebookShareLink(props.route, props.title)} className={childClass('link', 'facebook')} data-title="Share to Facebook"></OutboundLink>
    <OutboundLink href={createTwitterShareLink(props.route, props.title)} className={childClass('link', 'twitter')} data-title="Share to Twitter"></OutboundLink>
    <OutboundLink href={createEmailShareLink(props.route, props.title)} className={childClass('link', 'email')} data-title="Share via Email"></OutboundLink>
  </div >
}


export default FixedShareLinks;
