import * as React from 'react';
import './ArticleSubTitleBar.scss';
import { childClassClosure } from '@Functions';

const baseClass = 'article-subtitle-bar';
const childClass = childClassClosure(baseClass);

interface IProps {
  timeToRead?: number
  date?: Date
}

const ArticleSubTitleBar: React.SFC<IProps> = (props: IProps) => {

  return <div className={baseClass}><span className={childClass('date')}>{describeArticleDate(props.date)}</span><span className={childClass('reading-time')}>{props.timeToRead} min read</span></div>
}
export default ArticleSubTitleBar;

export function describeArticleDate(date: Date) {
  return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
}