import * as React from "react";
import { Link } from "gatsby";
import { childClassClosure, baseClassAnd } from "@Functions";
import "./_LinkCard.scss";
import { ArticleMetaData } from "@ViewModels";
import LinkWrapper from "components/texty/LinkWrapper";
import { describeArticleDate } from "components/texty/ArticleSubTitleBar";

export const baseClass = 'link-card';
export const childClass = childClassClosure(baseClass);

interface ArticleLinkCardProps {
  articleMeta: ArticleMetaData;
  className?: string;
  inPanel?: boolean; //Defaults to true
}

const ArticleLinkCard: React.SFC<ArticleLinkCardProps> = (props) => {
  return <div className={[baseClassAnd(baseClass, props), baseClassAnd(baseClass, props, props.inPanel == false ? '' : 'in-panel')].join(' ')} onClick={() => window.location.href = props.articleMeta.route}>
    <div className={childClass('image-wrapper')}>
      <img src={props.articleMeta.mainImage} alt={props.articleMeta.title} />
    </div>
    <div className={childClass('content-wrapper')}>
      <h2 className={childClass('title')}><LinkWrapper to={props.articleMeta.route}>{props.articleMeta.title}</LinkWrapper></h2>
      <p className={childClass('standfirst')}>{props.articleMeta.standfirst}</p>
      <footer className={childClass('footer')}>
        {props.articleMeta.date && <span>{describeArticleDate(props.articleMeta.date)}</span>}
      </footer>
    </div>
  </div >
}

export default ArticleLinkCard;
