import * as React from 'react';
import { ArticleMetaData } from '@ViewModels';
import { ensureTrailingSlash } from '@Functions';
import * as xssFilters from 'xss-filters';

export function getArticleStructuredData(article: ArticleMetaData) {
  return xssFilters.inHTMLData(JSON.stringify({
    "@context": "http://schema.org/",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://www.pedaldudes.com${ensureTrailingSlash(article.route)}`
    },
    "datePublished": article.date.toISOString(),
    "dateModified": article.date.toISOString(),
    "headline": article.title,
    "image": `https://www.pedaldudes.com${article.mainImage}`,
    "author": {
      "@type": "Organization",
      "name": "Pedal Dudes"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Pedal Dudes",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.pedaldudes.com//assets/media/logo.png"
      }
    },
    "description": article.standfirst
  }));
}
