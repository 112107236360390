import * as React from 'react';
import './FixedHeightImage.scss';
import { baseClassAnd } from '@Functions';

const baseClass = 'fixed-height-image';

interface FixedHeightImageProps {
  className?: string;
  src: string;
  height: string;
}

const FixedHeightImage: React.SFC<FixedHeightImageProps> = (props) => {
  return <div className={baseClassAnd(baseClass, props)} style={
    {
      backgroundImage: `url('${props.src}')`,
      height: props.height
    }}>
  </div>
}
export default FixedHeightImage;
