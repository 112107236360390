import * as React from "react";
import { childClassClosure, baseClassAnd } from "@Functions";
import "./RelatedArticlesList.scss";
import { ArticleMetaData } from "@ViewModels";
import ArticleLinkCard from "components/cards/ArticleLinkCard";

const baseClass = 'related-articles-list';
const childClass = childClassClosure(baseClass);

interface IProps {
  heading: string;
  articles: ArticleMetaData[];
  className?: string;
}

export default class RelatedArticlesList extends React.Component<IProps, {}> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return <div className={baseClassAnd(baseClass, this.props)}>
      <h3 className={childClass('heading')}>
        {this.props.heading}
      </h3>
      <ul className={childClass('list')}>
        {this.props.articles.map((articles, ci) =>
          <li key={ci}>
            <ArticleLinkCard articleMeta={articles} />
          </li>
        )}
      </ul>
    </div>
  }
}

